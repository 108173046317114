

































































































import Vue from "vue";
import { mapState } from "vuex";
import { download } from "@/utils";

export default Vue.extend({
  data() {
    return {};
  },
  computed: {
    ...mapState("app", {
      isCN: "isCN"
    })
  },
  methods: {
    onDownload() {
      download(
        "https://cdn-www.angrymiao.com/pdf/Blackdiamond75UserManual%E7%94%A8%E6%88%B7%E4%BD%BF%E7%94%A8%E6%89%8B%E5%86%8C.pdf"
      );
    },
    onOpen(url: string) {
      window.open(url, "_blank");
    }
  },
  mounted() {
    // if (!this.isCN) {
    //   this.$router.back();
    //   this.onOpen("https://bit.ly/3PHs2jO");
    // }
  }
});
